import {
	ChartSizes,
	GraphicTypes,
	LineStats,
	NumberTuple,
	Stats
} from '../models/types';

export const getHeightInPixels = (size: ChartSizes) => {
	switch (size) {
		case ChartSizes.small:
			return 220;

		case ChartSizes.medium:
			return 280;

		case ChartSizes.large:
			return 420;

		default:
			return 350;
	}
};

export const getMinMaxRangeFromArray = (array: number[]): [number, number] => [
	Math.round(Math.min(...array)),
	Math.ceil(Math.max(...array))
];

// TODO: Refactorer les types pour que ce soit générique et couplé entre les deux arguments
export const getMinMaxRangeFromStats = (
	graphicType: GraphicTypes,
	stats: Stats[number]
): NumberTuple | null => {
	if (!stats?.all) {
		return null;
	}
	if (graphicType === GraphicTypes.bar) {
		const values = Object.values(stats?.all)
			.map(({rule}) => rule)
			.filter((v) => v !== null);
		return getMinMaxRangeFromArray([0, ...values]);
	}

	if (graphicType === GraphicTypes.area || graphicType === GraphicTypes.line) {
		const values = Object.entries(stats as LineStats)
			.filter(([key, _]) => key !== 'all')
			.flatMap(([_, value]) => {
				return typeof value === 'number'
					? value
					: Object.values(value).flatMap(({rule}, _) => rule);
			});
		return getMinMaxRangeFromArray([0, ...values]);
	}

	return null;
};
