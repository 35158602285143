import {
	BarChart,
	CartesianGrid,
	Cell,
	Bar as RechartsBar,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	LabelList
} from 'recharts';
import {BarStats, ChartSizes, NumberTuple} from '../models/types';
import {getHeightInPixels} from '../utils/utils';
import ChartLegend from './ChartLegend';
import {useLabelsColor} from './useLabelsColor';

interface BarProps {
	data: BarStats['all'];
	size: ChartSizes;
	idTitle: string;
	domain?: NumberTuple | null;
	legend: boolean;
}

const Bar = ({data, size, idTitle, domain, legend}: BarProps) => {
	const colors = useLabelsColor();

	const formattedData = Object.entries(data).map(function ([key, value]) {
		let newValue: number = Math.floor(value.rule * 10) / 10;
		if (idTitle === 'indicator-24' || idTitle === 'indicator-title-24') {
			newValue = Number.parseFloat(newValue.toFixed(0));
		}

		return {
			name: value.label,
			value: newValue,
			xAxis: key
		};
	});

	// It's not very clean, but it saves you having to go back a long way to retrieve the indicator. You just check whether the label contains "ans", because if it does, it's indicator 5 or 38.2.
	// https://3.basecamp.com/4407563/buckets/33077106/card_tables/cards/7372804282
	const showXAxis: boolean = formattedData[0].name.includes('ans');

	return (
		<>
			<div style={{height: getHeightInPixels(size)}}>
				<ResponsiveContainer>
					<BarChart data={formattedData} maxBarSize={40}>
						<CartesianGrid strokeDasharray="3 3" />
						<YAxis
							domain={domain ?? [0, 'auto']}
							allowDecimals={false}
							interval={'preserveStartEnd'}
							allowDataOverflow={true}
						/>
						{showXAxis ? (
							<XAxis dataKey="xAxis" fontSize={12} fontWeight={'bold'} />
						) : (
							<XAxis hide dataKey="name" />
						)}
						<Tooltip
							contentStyle={{maxWidth: '15rem', whiteSpace: 'unset'}}
						/>
						<RechartsBar
							dataKey="value"
							fill="#0f91ab"
							name="Total"
							minPointSize={2}
							isAnimationActive={false}
						>
							<LabelList dataKey="value" position="top" />
							{formattedData.map(({name}, index) => (
								<Cell
									cursor="pointer"
									fill={
										colors?.bar?.[idTitle]
											? colors?.bar?.[idTitle]?.[name]
											: colors?.bar_gradient_colors?.[idTitle]?.[
													name
												]
									}
									key={`cell-${index}`}
								/>
							))}
						</RechartsBar>
					</BarChart>
				</ResponsiveContainer>
			</div>
			{legend && (
				<ChartLegend
					legends={formattedData}
					colors={
						colors?.bar?.[idTitle] ??
						colors?.bar_gradient_colors?.[idTitle]
					}
				/>
			)}
		</>
	);
};

export default Bar;
